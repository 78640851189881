import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import NewsList from "../components/NewsList"
import AOS from 'aos'
import 'aos/dist/aos.css'

class NewsListPage extends React.Component {
    
    constructor({ pageContext: { localeData }, data }) {    
        super()
        this.newsListPage = data.newsListPage
        this.bgImage = data.bgImage.childImageSharp
        this.newsList = data.newsList
        this.localeData = localeData        
    }

    componentDidMount() {
        AOS.init({
            once: true,
            easing: 'ease-in-out',
            useClassNames: true
        })
    }    

    render() {
        return (
            <Layout localeData={this.localeData} title={this.newsListPage.frontmatter.title}>
                <BookingLine localeData={this.localeData}/>
                <Breadcrumb slug={this.newsListPage.frontmatter.slug} title={this.newsListPage.frontmatter.title} bgImage={this.bgImage} />
                <NewsList data={this.newsListPage} newsList={this.newsList.edges} />
            </Layout>
        );
    }
};
export default withI18next()(NewsListPage);

export const newsListPageQuery = graphql`
  query($id: String!, $locale: String!) {
    newsListPage: markdownRemark(id: { eq: $id }){   
        html
        frontmatter {
            slug
            title
        }
    }
    bgImage: file(relativePath: { eq: "events/event_header_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    newsList: allMarkdownRemark(
    filter: {frontmatter: {templateKey: {eq: "news"}}, fields: {locale: {eq: $locale}}}
    sort: {fields: frontmatter___date, order: DESC}
    ) {      
        edges {
            node {
                frontmatter {
                    slug
                    title
                    date
                    description
                    image {
                        childImageSharp {
                            fluid(maxWidth: 770, quality: 90) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
}`