import React from "react";
import { Link } from "gatsby"
import Img from "gatsby-image"
import Heading from "../Heading"
import { useTranslation } from "react-i18next";

import './news_list.scss'

export default ({ data, newsList }) => {

    const { t } = useTranslation();
    const AOS_DELAY = 200

    return(
        <div className="news-list-area pt-95 pb-100">
		    <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading title={t('news-list-title')} subtitle={t('news-list-subtitle')} className="mb-50" />
                    </div>
                </div>
		        <div className="news-list-container">
                    {newsList.map(({node: news}, index) => (
                        <div className="row news-list-item no-gutters" data-aos="fade-left" data-aos-delay={(index + 1) * AOS_DELAY}>
                            <div className="news-list-image image-left col-sm-12 col-md-4 col-lg-4">
                                <Link to={news.frontmatter.slug}>
                                    <Img sizes={news.frontmatter.image.childImageSharp.fluid} />
                                </Link>
                            </div>
                            <div className="news-list-text col-md-8 col-lg-6">
                                <h3><Link to={news.frontmatter.slug}>{news.frontmatter.title}</Link></h3>
                                <p className="news-lead">{news.frontmatter.description}</p>
                                <Link to={news.frontmatter.slug} className="primary-btn col-md-auto d-none d-md-block d-lg-none pull-right">{t('details')} <i className="fa fa-caret-right" aria-hidden="true"></i></Link>
                            </div>
                            <Link to={news.frontmatter.slug} className="primary-btn col-md-auto d-md-none d-lg-block pull-right">{t('details')} <i className="fa fa-caret-right" aria-hidden="true"></i></Link>
                        </div>
                    ))}
                </div>
		    </div>
        </div>
        
    )}